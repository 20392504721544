import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import DynamicForm from '../../components/DynamicForm';
import ApiCaller from '../../components/ApiCaller';
import 'react-toastify/dist/ReactToastify.css';

export default function LeadInstructions() {
    const [refresh, setRefresh] = useState(false);

    const handleSubmit = async (formData, method, url, sendData) => {
        try {
            const savedField = await sendData(method, url, formData);
            setRefresh(!refresh); // Trigger a refresh
            return savedField; // Return the saved data
        } catch (error) {
            throw error; // Re-throw the error to be caught by the caller
        }
    };

    const handleDelete = async (field, sendData) => {
        try {
            await sendData('DELETE', `/conversations/${field}`);
            setRefresh(!refresh); // Trigger a refresh
        } catch (error) {
            console.error('Errore nell\'eliminazione della conversazione');
        }
    };

    return (
        <Box sx={{ display: { xs: 'block' } }}>
            <ApiCaller pageName="/conversations" method="GET" queryParams="" refresh={refresh}>
                {({ data, loading, error, sendData }) => (
                    <>
                        {loading && <p style={{ textAlign: 'center' }}>Loading...</p>}
                        {error && <p>Error: {error.message}</p>}
                        {Array.isArray(data) && data.length > 0 ? (
                            <DynamicForm
                                fields={[
                                    ...data.map(conversation => ({
                                        name: conversation.id.toString(),
                                        label: '',
                                        type: 'input',
                                        required: true,
                                        value: conversation.content,
                                    })),
                                    {
                                        name: 'content',
                                        label: 'Nuova Istruzione',
                                        type: 'input',
                                        required: false,
                                        value: '',
                                    }
                                ]}
                                onSubmit={(formData, method, url) => handleSubmit(formData, method, url, sendData)}
                                onDelete={(field) => handleDelete(field, sendData)}
                                title='Gestione delle Leads'
                                description='Modifica, aggiungi o elimina i campi che si vogliono intercettare e salvare durante la conversazione tra utente e chat'
                                initialValues={data.reduce((acc, conversation) => {
                                    acc[conversation.id.toString()] = conversation.content;
                                    return acc;
                                }, {})}
                                autoSave={true}
                                baseUrl="/conversations"
                                nameInput="content"
                                nameLabel="Nuova istruzione"
                                deleting={true}
                            />
                        ) : (
                            <p style={{ textAlign: 'center' }}>No conversations found.</p>
                        )}
                    </>
                )}
            </ApiCaller>
        </Box>
    );
}
