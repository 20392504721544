import * as React from 'react';
import Sheet from '@mui/joy/Sheet';
import MessagesPane from '../../components/MessagesPane';
import ChatsPane from '../../components/ChatsPane';
import ApiCaller from '../../components/ApiCaller';
import Echo from 'laravel-echo';
import io from 'socket.io-client';
import { API_ENDPOINT } from '../../constants/constants';
import Cookies from 'js-cookie';

// Configura Socket.io client
window.io = io;

window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: 'https://web.customerserver053002.eurhosting.net:6001',
    transports: ['websocket'],
    wsPort: 6001,
    wssPort: 6001,
    encrypted: true,
    enabledTransports: ['ws', 'wss'],
    auth: {
        headers: {
            'X-App-Id': 'fef0f2a839b94980',
            'Authorization': 'Bearer ' + '455ce612cba9810eb2d10646abc9ece7'
        }
    }
});

export default function ChatList() {
    const [selectedChat, setSelectedChat] = React.useState(null);
    const [chats, setChats] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        const channel = window.Echo.channel('chat')
            .listen('.new-chat', (data) => {
                console.log('Nuovo messaggio ricevuto:', data);
                updateChats(data.message);
            })
            .error((error) => {
                console.error('Errore di connessione:', error);
            });

        return () => {
            channel.stopListening('.new-chat');
            window.Echo.leave('chat');
        };
    }, []);

    const updateChats = (newMessage) => {
        if (!newMessage || !newMessage.session) {
            console.error('Messaggio non valido:', newMessage);
            return;
        }

        setChats((prevChats) => {
            const existingChatIndex = prevChats.findIndex(chat => chat.session === newMessage.session);
            if (existingChatIndex !== -1) {
                const updatedChats = [...prevChats];
                updatedChats[existingChatIndex] = {
                    ...updatedChats[existingChatIndex],
                    messages: [...updatedChats[existingChatIndex].messages, newMessage]
                };
                console.log('Chat aggiornata:', updatedChats);
                return updatedChats;
            }
            const newChat = { session: newMessage.session, messages: [newMessage], sender: { name: 'Anonimo' } };

            return [...prevChats, newChat];
        });
    };

    React.useEffect(() => {
        console.log('Stato delle chats aggiornato:', chats);
    }, [chats]);

    const handleApiData = (data) => {
        console.log('Dati ricevuti dall\'API:', data);
        setChats(data.chat); // Cambiato per estrarre il json annidato
        if (!selectedChat && data.chat.length > 0) {
            setSelectedChat(data.chat[0]);
        }
        setLoading(false);
    };

    const handleApiError = (err) => {
        console.error('Errore API:', err);
        setError(err);
        setLoading(false);
    };

    // Funzione per aggiornare lo stato delle chat
    const updateChatStatus = (sessionId) => {
        setChats(prevChats =>
            prevChats.map(chat =>
                chat.session === sessionId
                    ? {
                        ...chat,
                        messages: chat.messages.map(message => ({ ...message, unread: 0 }))
                    }
                    : chat
            )
        );
    };

    return (
        <ApiCaller
            pageName="/chat"
            method="GET"
            queryParams=""
            onSuccess={handleApiData}
            onError={handleApiError}
        >
            {({ data, loading, error }) => (
                <Sheet
                    sx={{
                        flex: 1,
                        width: '100%',
                        mx: 'auto',
                        pt: 'var(--Header-height)',
                        display: 'grid',
                        gridTemplateColumns: {
                            xs: '1fr',
                            sm: 'minmax(min-content, min(30%, 400px)) 1fr',
                        },
                        height: '100vh', // Ensure it does not overflow beyond the viewport
                        overflow: 'hidden',
                    }}
                >
                    {loading && <p style={{ textAlign: 'center' }}>Loading...</p>}
                    {error && <p>Error: {error.message}</p>}
                    {!loading && !error && chats.length > 0 && (
                        <>
                            <ChatsPane
                                chats={chats}
                                selectedChatId={selectedChat?.session}
                                setSelectedChat={setSelectedChat}
                                updateChatStatus={updateChatStatus} // Passa la funzione di aggiornamento
                            />
                            <MessagesPane chat={selectedChat} />
                        </>
                    )}
                </Sheet>
            )}
        </ApiCaller>
    );
}
