import * as React from 'react';
import Stack from '@mui/joy/Stack';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import List from '@mui/joy/List';
import ChatListItem from './ChatListItem';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'js-cookie';
import { API_ENDPOINT } from '../constants/constants';

const ChatsPane = ({ chats: initialChats, selectedChatId, setSelectedChat, updateChatStatus }) => {
    const [chats, setChats] = React.useState(initialChats || []);
    const [loading, setLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [lastPage, setLastPage] = React.useState(3); // Imposta il numero di pagine totali conosciute
    const [searchTerm, setSearchTerm] = React.useState("");

    const handleScroll = async (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && !loading && currentPage < lastPage) {
            setLoading(true);
            await fetchMoreChats(currentPage + 1);
        }
    };

    const fetchMoreChats = async (page) => {
        const token = Cookies.get('access_token');
        try {
            const response = await fetch(`${API_ENDPOINT}/chat?page=${page}&per_page=10`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setChats((prevChats) => [...prevChats, ...data.chat]);
            setCurrentPage(data.current_page);
            setLastPage(data.last_page);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching more chats:', error);
            setLoading(false);
        }
    };

    const sortedChats = React.useMemo(() => {
        const filteredChats = chats.filter(chat =>
            chat.messages.some(message => message.message.toLowerCase().includes(searchTerm.toLowerCase()))
        );

        return [...filteredChats].sort((a, b) => {
            const lastMessageA = a.messages[a.messages.length - 1];
            const lastMessageB = b.messages[b.messages.length - 1];
            return new Date(lastMessageB.timestamp) - new Date(lastMessageA.timestamp);
        });
    }, [chats, searchTerm]);

    const handleChatClick = (chat) => {
        setSelectedChat(chat);
        setLoading(false); // Nascondi l'icona di caricamento quando l'utente clicca su una chat
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <Sheet
            sx={{
                borderRight: '1px solid',
                borderColor: 'divider',
                height: 'calc(100vh - 92px)',
                overflowY: 'auto',
            }}
            onScroll={handleScroll}
        >
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                p={2}
                pb={1.5}
            >
                <Typography
                    fontSize={{ xs: 'md', md: 'lg' }}
                    component="h1"
                    fontWeight="lg"
                >
                    Messaggi
                </Typography>
            </Stack>
            <Box sx={{ px: 2, pb: 1.5 }}>
                <Input
                    size="sm"
                    placeholder="Search"
                    aria-label="Search"
                    value={searchTerm}
                    onChange={handleSearch}
                />
            </Box>
            <List
                sx={{
                    py: 0,
                    '--ListItem-paddingY': '0.75rem',
                    '--ListItem-paddingX': '1rem',
                }}
            >
                {sortedChats.map((chat) => (
                    <ChatListItem
                        key={chat.session}
                        chat={chat}
                        selected={chat.session === selectedChatId}
                        setSelectedChat={handleChatClick}
                        updateChatStatus={updateChatStatus}
                    />
                ))}
                {loading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
                        <CircularProgress />
                    </Box>
                )}
            </List>
        </Sheet>
    );
};

export default ChatsPane;
