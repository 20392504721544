import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import ApiCaller from '../../components/ApiCaller';

export default function SettingsWebsite() {
    return (
        <Box sx={{ display: { xs: 'block' } }}>
            Website settings
        </Box>
    )
}