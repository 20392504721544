// src/pages/chat/ChatInstructions.js
import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import DynamicForm from "../../components/DynamicForm";
import ApiCaller from '../../components/ApiCaller';
import 'react-toastify/dist/ReactToastify.css';

export default function ChatInstructions() {
    const [refresh, setRefresh] = useState(false);
    const [page, setPage] = useState(0);

    const handleSubmit = async (formData, method, url, sendData) => {
        console.log('Submitting form data:', formData);
        try {
            await sendData(method, url, formData); // Passa l'ID dell'istruzione
            setRefresh(!refresh); // Trigger refresh after submit
        } catch (error) {
            console.error('Errore durante l\'invio del modulo:', error);
        }
    };

    const fields = [
        { name: 'content', type: 'textarea', label: 'Istruzioni', required: true },
    ];

    return (
        <Box sx={{ display: { xs: 'block' } }}>
            <ApiCaller pageName="/instructions" method="GET" queryParams="" refresh={refresh} page={page}>
                {({ data, loading, error, sendData }) => (
                    <>
                        {loading && <p style={{ textAlign: 'center' }}>Loading...</p>}
                        {error && <p>Error: {error.message}</p>}
                        {data && data.length > 0 && (
                            <DynamicForm
                                fields={fields}
                                onSubmit={(formData, method, url) => handleSubmit(formData, method, url, sendData)}
                                title='Aggiungi/Modifica le istruzioni'
                                description='Crea delle istruzioni il più chiare possibili da passare a chat GPT per essere istruito'
                                initialValues={data[0]}
                                baseUrl="/instructions"
                                autoSave={false}
                            />
                        )}
                    </>
                )}
            </ApiCaller>
        </Box>
    );
}
