import * as React from 'react';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import ChatBubble from './ChatBubble';
import MessageInput from './MessageInput';
import MessagesPaneHeader from './MessagesPaneHeader';
import { API_ENDPOINT } from '../constants/constants';
import Cookies from "js-cookie";
import Echo from 'laravel-echo';
import io from 'socket.io-client';

// Configura Socket.io client
window.io = io;

window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: 'https://web.customerserver053002.eurhosting.net:6001',
    transports: ['websocket'],
    wsPort: 6001,  // Porta per WebSocket non criptato
    wssPort: 6001,  // Porta per WebSocket criptato
    encrypted: true,
    enabledTransports: ['ws', 'wss'],
    auth: {
        headers: {
            'X-App-Id': 'fef0f2a839b94980',
            'Authorization': 'Bearer ' + '455ce612cba9810eb2d10646abc9ece7'
        }
    }
});

const MessagesPane = ({ chat }) => {
    const [chatMessages, setChatMessages] = React.useState([]);
    const [textAreaValue, setTextAreaValue] = React.useState('');
    const [controlState, setControlState] = React.useState(() => {
        const savedState = localStorage.getItem('controlState');
        return savedState ? JSON.parse(savedState) : {};
    });
    const [isOnline, setIsOnline] = React.useState(false);
    const messagesEndRef = React.useRef(null);
    const messagesContainerRef = React.useRef(null);

    React.useEffect(() => {
        if (chat && chat.messages) {
            const formattedMessages = chat.messages.map((msg) => ({
                id: `${msg.id}-message`,
                content: msg.message,
                timestamp: formatTimestamp(msg.timestamp),
                sender: msg.sender,
            }));
            setChatMessages(formattedMessages);
            scrollToBottom();
            updateOnlineStatus(formattedMessages);
        }
    }, [chat]);

    React.useEffect(() => {
        scrollToBottom();
    }, [chatMessages]);

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            updateOnlineStatus(chatMessages);
        }, 30000); // Aggiorna ogni 30 secondi

        return () => clearInterval(intervalId); // Pulizia dell'interval alla disattivazione del componente
    }, [chatMessages]);

    const displaySender = chat && chat.sender && chat.sender.name ? chat.sender : { name: "Anonimo" };
    const leads = chat && chat.leads ? chat.leads : '';
    const lead_id = chat && chat.lead_id ? chat.lead_id : '';
    const scrollToBottom = () => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    };

    const session = chat ? chat.session : null;
    const hasControl = session ? (controlState[session] || false) : false;

    const handleTakeControl = async (session) => {
        const token = Cookies.get('access_token');
        try {
            const response = await fetch(`${API_ENDPOINT}/chat/getcontrol`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ session }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const newControlState = { ...controlState, [session]: true };
            setControlState(newControlState);
            localStorage.setItem('controlState', JSON.stringify(newControlState));
        } catch (error) {
            console.error('Error taking control:', error);
        }
    };

    const handleSubmitMessage = async () => {
        const token = Cookies.get('access_token');
        try {
            const response = await fetch(`${API_ENDPOINT}/chat/send`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ session, message: textAreaValue }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const newId = chatMessages.length + 1;
            const newIdString = newId.toString();
            const currentTime = formatTimestamp(new Date().toISOString());
            const newMessage = {
                id: newIdString,
                sender: 'you',
                content: textAreaValue,
                timestamp: currentTime,
            };
            setIsOnline(true)
            setChatMessages([...chatMessages, newMessage]);
            setTextAreaValue('');
            scrollToBottom();
            updateOnlineStatus([...chatMessages, newMessage]);
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    React.useEffect(() => {
        if (session) {
            console.log("Tentativo di uso WS per chat." + session);

            const channel = window.Echo.channel(`chat.${session}`)
                .listen('.conversations', (data) => {
                    console.log('Messaggio ricevuto:', data);

                    // Verifica che i dati siano definiti e validi
                    if (data && data.message) {
                        // Verifica e formatta il timestamp
                        const timestamp = formatTimestamp(data.message.timestamp);

                        const newMessage = {
                            id: data.message.id,
                            content: data.message.message,
                            timestamp: timestamp,
                            sender: data.message.sender || 'Anonimo',
                        };
                        setChatMessages((prevMessages) => {
                            const updatedMessages = [...prevMessages, newMessage];
                            if (newMessage.sender !== 'you') { // Aggiorna solo se il messaggio non è dell'utente
                                setIsOnline(true)
                            }
                            return updatedMessages;
                        });
                    } else {
                        console.error('Dati del messaggio non validi:', data);
                    }
                })
                .error((error) => {
                    console.error('Errore di connessione:', error);
                });

            return () => {
                console.log('Interrompo l\'ascolto del canale chat.' + session);
                channel.stopListening('.conversations');
                window.Echo.leave(`chat.${session}`);
            };
        } else {
            console.warn('Sessione non definita.');
        }
    }, [session]);

    // Funzione per aggiornare lo stato online
    const updateOnlineStatus = (messages) => {
        const userMessages = messages.filter(message => message.sender === 'user');
        if (userMessages.length > 0) {
            const lastUserMessage = new Date(userMessages[userMessages.length - 1].timestamp);
            const now = new Date();
            const diffInMinutes = (now - lastUserMessage) / 1000 / 60;
            setIsOnline(diffInMinutes <= 3);
        } else {
            setIsOnline(false);
        }
    };

    return (
        <Sheet
            sx={{
                height: 'calc(100vh - 72px)',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'background.level1',
            }}
        >
            <MessagesPaneHeader sender={displaySender} leads={leads} lead_id={lead_id} />
            <Box
                ref={messagesContainerRef}
                sx={{
                    display: 'flex',
                    flex: 1,
                    minHeight: 0,
                    px: 2,
                    py: 3,
                    overflowY: 'auto',
                    flexDirection: 'column',
                }}
            >
                <Stack spacing={2} justifyContent="flex-start">
                    {chatMessages.map((message, index) => {
                        const isYou = message.sender === 'you';
                        return (
                            <Stack
                                key={index}
                                direction="row"
                                spacing={2}
                                flexDirection={isYou ? 'row-reverse' : 'row'}
                            >
                                <ChatBubble
                                    variant={isYou ? 'sent' : 'received'}
                                    content={message.content}
                                    timestamp={message.timestamp}
                                    sender={isYou ? { name: 'Tu' } : { name: chat.sender.name }}
                                />
                            </Stack>
                        );
                    })}
                    <div ref={messagesEndRef} />
                </Stack>
            </Box>
            {isOnline && (
                <MessageInput
                    textAreaValue={textAreaValue}
                    setTextAreaValue={setTextAreaValue}
                    onSubmit={handleSubmitMessage}
                    hasControl={hasControl}
                    onTakeControl={() => handleTakeControl(session)}
                />
            )}
        </Sheet>
    );
};

function formatTimestamp(isoString) {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export default MessagesPane;