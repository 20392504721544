import * as React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MemoizedSidebarWithMemo from './components/Sidebar';
import Header from './components/Header';
import Percorsi from './components/Routes';
import Login from './pages/users/Login';
import { AuthProvider, AuthContext } from './contexts/AuthContext';

const ProtectedRoute = ({ children }) => {
    const { auth } = React.useContext(AuthContext);
    return auth.token ? children : <Navigate to="/login" />;
};

export default function App() {
    return (
        <CssVarsProvider>
            <AuthProvider>
                <Router>
                    <CssBaseline />
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="/*"
                            element={
                                <ProtectedRoute>
                                    <AuthenticatedApp />

                                </ProtectedRoute>
                            }
                        />
                        <Route path="*" element={<Navigate to="/login" />} />
                    </Routes>
                </Router>
            </AuthProvider>
        </CssVarsProvider>
    );
}

const AuthenticatedApp = () => (
    <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <Header />
        {MemoizedSidebarWithMemo()}
        <Box
            component="main"
            className="MainContent"
            sx={{
                px: 0,
                pt: 'calc(52px + 12px)',
                pb: '0',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                height: '100dvh',
                gap: 1,
                overflow: 'auto',
            }}
        >
            <Percorsi />
        </Box>
    </Box>
);
