import React, { useState, useMemo, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import CategoryIcon from '@mui/icons-material/Category';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import WebIcon from '@mui/icons-material/Web';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import BrightnessAutoRoundedIcon from '@mui/icons-material/BrightnessAutoRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AuthContext } from '../contexts/AuthContext';
import { APP_VERSION } from '../constants/constants'; // Importa la versione dell'app
import ColorSchemeToggle from './ColorSchemeToggle';
import { closeSidebar } from '../utils';

function Toggler({
                     defaultExpanded = false,
                     renderToggle,
                     children,
                 }) {
    const [open, setOpen] = useState(defaultExpanded);
    return (
        <>
            {renderToggle({ open, setOpen })}
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: open ? '1fr' : '0fr',
                    transition: '0.2s ease',
                    '& > *': {
                        overflow: 'hidden',
                    },
                }}
            >
                {children}
            </Box>
        </>
    );
}

const menuItems = [
    { label: 'Chat', icon: <DescriptionRoundedIcon />, items: [
            { label: 'Conversazioni', href: '/chat/list' },
            { label: 'Gestisci API', href: '/chat/api' },
            { label: 'Settings', href: '/chat/setting' },
            { label: 'Istruzioni GPT', href: '/chat/instructions' },
        ]},
    { label: 'Leads', icon: <WebIcon />, items: [
            { label: 'Lista Leads', href: '/leads/list' },
            { label: 'Istruzioni Leads', href: '/leads/instructions' }
        ]},
    { label: 'Settings', icon: <CategoryIcon />, items: [
            { label: 'API setting', href: '/settings/api' },
            { label: 'Website setting', href: '/settings/website' },
            { label: 'CRM integration', href: '/settings/crm' }
        ]},
];

const Sidebar = () => {
    const [expandedItem, setExpandedItem] = useState(null);
    const [activeLink, setActiveLink] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const { auth, logout } = useContext(AuthContext);
    const currentPath = location.pathname;

    const handleToggle = (item) => {
        setExpandedItem(expandedItem === item ? null : item);
    };

    const handleLinkClick = (link) => {
        setActiveLink(link);
    };

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    return (
        <Sheet
            className="Sidebar"
            sx={{
                position: { xs: 'fixed', md: 'sticky' },
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                    md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 10000,
                height: '100dvh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 2,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Sidebar-width': '220px',
                        [theme.breakpoints.up('lg')]: {
                            '--Sidebar-width': '240px',
                        },
                    },
                })}
            />
            <Box
                className="Sidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 9998,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    opacity: 'var(--SideNavigation-slideIn)',
                    backgroundColor: 'var(--joy-palette-background-backdrop)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
                onClick={() => closeSidebar()}
            />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <IconButton variant="soft" color="primary" size="sm">
                    <BrightnessAutoRoundedIcon />
                </IconButton>
                <Typography level="title-lg">Art.™</Typography>

            </Box>
            <Input size="sm" startDecorator={<SearchRoundedIcon />} placeholder="Search" />
            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    [`& .${listItemButtonClasses.root}`]: {
                        gap: 1.5,
                    },
                }}
            >
                <List
                    size="sm"
                    sx={{
                        gap: 1,
                        '--List-nestedInsetStart': '30px',
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                    }}
                >
                    {menuItems.map((menuItem, index) => {
                        const isExpanded = menuItem.items.some(subItem => currentPath.includes(subItem.href));
                        return (
                            <ListItem nested key={index}>
                                <Toggler defaultExpanded={isExpanded}
                                         renderToggle={({ open, setOpen }) => (
                                             <ListItemButton onClick={() => setOpen(!open)}>
                                                 {menuItem.icon}
                                                 <ListItemContent>
                                                     <Typography level="title-sm">{menuItem.label}</Typography>
                                                 </ListItemContent>
                                                 <KeyboardArrowDownIcon
                                                     sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                                                 />
                                             </ListItemButton>
                                         )}
                                >
                                    <List sx={{ gap: 0.5 }}>
                                        {menuItem.items.map((subItem, subIndex) => {
                                            const isSelected = currentPath.includes(subItem.href);
                                            return (
                                                <ListItem sx={{ mt: 0.5 }} key={subIndex}>
                                                    <ListItemButton
                                                        component="a"
                                                        href={subItem.href}
                                                        selected={isSelected}
                                                    >
                                                        {subItem.label}
                                                    </ListItemButton>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </Toggler>
                            </ListItem>
                        );
                    })}
                </List>
                <List
                    size="sm"
                    sx={{
                        mt: 'auto',
                        flexGrow: 0,
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                        '--List-gap': '8px',
                        mb: 2,
                    }}
                >
                    <ListItem>
                        <ListItemButton>
                            <SupportRoundedIcon />
                            Support
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton>
                            <SettingsRoundedIcon />
                            Settings
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Avatar
                    variant="outlined"
                    size="sm"
                    src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
                />
                <Box sx={{ minWidth: 0, flex: 1 }}>
                    <Typography level="title-sm">Siriwat K.</Typography>
                    <Typography level="body-xs">siriwatk@test.com</Typography>
                </Box>
                <IconButton size="sm" variant="plain" color="neutral" onClick={handleLogout}>
                    <LogoutRoundedIcon />
                </IconButton>
            </Box>
            <Typography level="body-xs" textAlign="center" sx={{ mt: 2, color: 'text.secondary' }}>
                Version {APP_VERSION}
            </Typography>
        </Sheet>
    );
};

const MemoizedSidebar = React.memo(Sidebar);

const MemoizedSidebarWithMemo = () => {
    return useMemo(() => <MemoizedSidebar />, []); // Memorizza nella cache la Sidebar con useMemo
};

export default MemoizedSidebarWithMemo;
