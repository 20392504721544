import React, { useState, useEffect, useContext } from 'react';
import { API_ENDPOINT } from '../constants/constants';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import api from '../api';
import { AuthContext } from '../contexts/AuthContext';

const ApiCaller = ({ pageName, queryParams, method, refresh, page, onSuccess, onError, children }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { auth } = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let url = `${API_ENDPOINT}${pageName}`;

                if (queryParams) {
                    url += queryParams;
                }

                url += `?page=${page || 1}&per-page=${Cookies.get('filterPerPage') || '10'}&sort=${Cookies.get('filterOrder') || 'id'}`;

                if (Cookies.get('filterSearch')) {
                    url += `&q=${Cookies.get('filterSearch')}`;
                }

                const response = await api.get(url);
                setData(response.data);
                onSuccess && onSuccess(response.data);  // Call onSuccess callback if provided
            } catch (error) {
                setError(error);
                onError && onError(error);  // Call onError callback if provided
                toast.error('Errore nella chiamata API', {
                    autoClose: 4000,
                    theme: 'light',
                });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [pageName, queryParams, method, refresh, page]);

    const sendData = async (method, url, formData) => {
        setLoading(true);
        try {
            const response = await api({
                method,
                url: `${API_ENDPOINT}${url}`,
                data: formData,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });
            setData(response.data);
            toast.success('Operazione eseguita con successo', {
                autoClose: 4000,
                theme: 'light',
            });
        } catch (error) {
            setError(error);
            toast.error("Errore nell'invio dei dati", {
                autoClose: 4000,
                theme: 'light',
            });
        } finally {
            setLoading(false);
        }
    };

    const deleteData = async (url) => {
        setLoading(true);
        try {
            await api.delete(`${API_ENDPOINT}${url}`);
            toast.success('Elemento eliminato con successo', {
                autoClose: 4000,
                theme: 'light',
            });
            setData(data.filter(item => item.id !== url.split('/').pop()));
        } catch (error) {
            setError(error);
            toast.error('Errore durante l\'eliminazione dell\'elemento', {
                autoClose: 4000,
                theme: 'light',
            });
        } finally {
            setLoading(false);
        }
    };

    if (typeof children === 'function') {
        return children({ data, loading, error, sendData, deleteData });
    }

    return null;
};

export default ApiCaller;
