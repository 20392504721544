// src/components/Header.js
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useColorScheme } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { AuthContext } from '../contexts/AuthContext';
import { toggleSidebar } from '../utils';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ColorSchemeToggle from "./ColorSchemeToggle";

export default function Header() {
    const { logout } = React.useContext(AuthContext);
    const navigate = useNavigate();
    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const { mode, setMode } = useColorScheme();
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => {
        setMounted(true);
    }, []);
    if (!mounted) {
        return <IconButton size="sm" variant="outlined" color="primary" />;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'fixed',
                top: 0,
                width: '100vw',
                height: 'var(--Header-height)',
                zIndex: 9995,
                p: 2,
                gap: 1,
                borderBottom: '1px solid',
                borderColor: 'background.level1',
                boxShadow: 'sm',
                backgroundColor: 'background.body', // Ensure background color for the header
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                }}
            >
                <IconButton
                    onClick={() => toggleSidebar()}
                    variant="outlined"
                    color="neutral"
                    size="sm"
                >
                    <MenuIcon />
                </IconButton>
                <Typography level="title-lg">Art.™</Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                }}
            >

            <IconButton
                variant="outlined"
                color="neutral"
                size="sm"
            >
                <NotificationsIcon />
            </IconButton>
                <ColorSchemeToggle sx={{ ml: 'auto' }} />
            <IconButton
                onClick={handleLogout}
                variant="outlined"
                color="neutral"
                size="sm"
            >
                <LogoutRoundedIcon />
            </IconButton>
            </Box>
        </Box>
    );
}
