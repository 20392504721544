import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import Textarea from '@mui/joy/Textarea';
import { IconButton, Stack } from '@mui/joy';
import FormatBoldRoundedIcon from '@mui/icons-material/FormatBoldRounded';
import FormatItalicRoundedIcon from '@mui/icons-material/FormatItalicRounded';
import StrikethroughSRoundedIcon from '@mui/icons-material/StrikethroughSRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';

export type MessageInputProps = {
    textAreaValue: string;
    setTextAreaValue: (value: string) => void;
    onSubmit: () => void;
    hasControl: boolean;
    onTakeControl: () => void;
};

export default function MessageInput({ textAreaValue, setTextAreaValue, onSubmit, hasControl, onTakeControl }: MessageInputProps) {
    const handleClick = () => {
        if (textAreaValue.trim() !== '') {
            onSubmit();
            setTextAreaValue('');
        }
    };

    return (
        <Box sx={{ px: 2, pb: 3 }}>
            <FormControl>
                <Textarea
                    placeholder="Type something here…"
                    aria-label="Message"
                    onChange={(e) => setTextAreaValue(e.target.value)}
                    value={textAreaValue}
                    minRows={3}
                    maxRows={10}
                    endDecorator={
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            flexGrow={1}
                            sx={{
                                py: 1,
                                pr: 1,
                                borderTop: '1px solid',
                                borderColor: 'divider',
                            }}
                        >
                            <div>
                                <IconButton size="sm" variant="plain" color="neutral">
                                    <FormatBoldRoundedIcon />
                                </IconButton>
                                <IconButton size="sm" variant="plain" color="neutral">
                                    <FormatItalicRoundedIcon />
                                </IconButton>
                                <IconButton size="sm" variant="plain" color="neutral">
                                    <StrikethroughSRoundedIcon />
                                </IconButton>
                                <IconButton size="sm" variant="plain" color="neutral">
                                    <FormatListBulletedRoundedIcon />
                                </IconButton>
                            </div>
                            {hasControl ? (
                                <Button
                                    size="sm"
                                    color="primary"
                                    sx={{ alignSelf: 'center', borderRadius: 'sm' }}
                                    endDecorator={<SendRoundedIcon />}
                                    onClick={handleClick}
                                >
                                    Invia
                                </Button>
                            ) : (
                                <Button
                                    size="sm"
                                    color="primary"
                                    sx={{ alignSelf: 'center', borderRadius: 'sm' }}
                                    onClick={onTakeControl}
                                >
                                    Prendi il controllo
                                </Button>
                            )}
                        </Stack>
                    }
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
                            handleClick();
                        }
                    }}
                    sx={{
                        '& textarea:first-of-type': {
                            minHeight: 72,
                        },
                    }}
                />
            </FormControl>
        </Box>
    );
}
