// src/api.js
import axios from 'axios';
import Cookies from 'js-cookie';
import { API_ENDPOINT } from './constants/constants';
const api = axios.create({
    baseURL: API_ENDPOINT,
});


api.interceptors.request.use(
    config => {
        const token = Cookies.get('access_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

api.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const refreshToken = Cookies.get('refresh_token');
                const { data } = await axios.post(`${API_ENDPOINT}/auth/refresh`, { refresh_token: refreshToken });
                const { access_token, refresh_token } = data;
                Cookies.set('access_token', access_token, { expires: 1 });
                Cookies.set('refresh_token', refresh_token, { expires: 1 });
                api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
                originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
                return api(originalRequest);
            } catch (refreshError) {
                console.error('Refresh token failed', refreshError);
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);

export const getChats = async () => {
    const response = await api.get('/chat');
    return response.data;
};

export default api;
