import * as React from 'react';
import {lazy, Suspense} from "react";
import {HashRouter as Router, BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';

import ChatList from '../pages/chat/list';
import ChatInstruction from '../pages/chat/instructions';
import ChatSetting from '../pages/chat/setting';
import ChatApi from '../pages/chat/api';
import LeadsList from '../pages/leads/list';
import LeadsInstructions from '../pages/leads/instructions';
import SettingsApi from '../pages/settings/api';
import SettingsCrm from '../pages/settings/crm';
import SettingsWebsite from '../pages/settings/website';

function Percorsi() {
    return (
        <Suspense fallback={<div></div>}>
                <Routes>
                    <Route path="/chat/list" element={<ChatList/>}/>
                    <Route path="/chat/instructions" element={<ChatInstruction/>}/>
                    <Route path="/chat/setting" element={<ChatSetting/>}/>
                    <Route path="/chat/api" element={<ChatApi/>}/>
                    <Route path="/leads/list" element={<LeadsList/>}/>
                    <Route path="/leads/instructions" element={<LeadsInstructions/>}/>
                    <Route path="/settings/api" element={<SettingsApi/>}/>
                    <Route path="/settings/crm" element={<SettingsCrm/>}/>
                    <Route path="/settings/website" element={<SettingsWebsite/>}/>
                    {/* <Route path="*" element={<Navigate to="/categorie/list" />} />*/}

                </Routes>

        </Suspense>
    );
}

export default Percorsi;
