import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { toggleMessagesPane } from '../utils';
import { useNavigate } from 'react-router-dom';
import { differenceInMinutes } from 'date-fns';
import ApiCaller from './ApiCaller';
import { API_ENDPOINT } from '../constants/constants';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ListDivider from '@mui/joy/ListDivider'

const MessagesPaneHeader = ({ sender, lastMessageTimestamp, session, leads, lead_id }) => {
    const displaySender = sender && sender.name ? sender : { name: "Unknown" };

    const navigate = useNavigate();

    const lastMessageDate = new Date(lastMessageTimestamp);
    const now = new Date();
    const isOnline = differenceInMinutes(now, lastMessageDate) <= 3;

    const [open, setOpen] = React.useState(false);

    const handleOpenChange = React.useCallback((event, isOpen) => {
        setOpen(isOpen);
    }, []);


    const handleGenerateLead = async () => {
        try {
            const response = await fetch(`${API_ENDPOINT}/lead/generation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ session }),
            });

            if (!response.ok) {
                throw new Error('Failed to generate lead');
            }

            const data = await response.json();
            console.log('Lead generated:', data);
            // Handle the response as needed
        } catch (error) {
            console.error('Error generating lead:', error);
        }
    };

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
                borderBottom: '1px solid',
                borderColor: 'divider',
                backgroundColor: 'background.body',
            }}
            py={{ xs: 2, md: 2 }}
            px={{ xs: 1, md: 2 }}
        >
            <Stack direction="row" spacing={{ xs: 1, md: 2 }} alignItems="center">
                <IconButton
                    variant="plain"
                    color="neutral"
                    size="sm"
                    sx={{
                        display: { xs: 'inline-flex', sm: 'none' },
                    }}
                    onClick={() => toggleMessagesPane()}
                >
                    <ArrowBackIosNewRoundedIcon />
                </IconButton>
                <Avatar size="lg" src="/static/images/avatar/1.jpg" />
                <div>
                    <Typography
                        fontWeight="lg"
                        fontSize="lg"
                        component="h2"
                        noWrap
                        endDecorator={
                            isOnline && (
                                <Chip
                                    variant="outlined"
                                    size="sm"
                                    color="neutral"
                                    sx={{
                                        borderRadius: 'sm',
                                    }}
                                    startDecorator={
                                        <CircleIcon sx={{ fontSize: 8 }} color="success" />
                                    }
                                    slotProps={{ root: { component: 'span' } }}
                                >
                                    Online
                                </Chip>
                            )
                        }
                    >
                        {displaySender.name}
                    </Typography>
                    <Typography level="body-sm">@{displaySender.name}</Typography>
                </div>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
                {displaySender.name !== 'Anonimo' && (

                    <Dropdown open={open} onOpenChange={handleOpenChange}>
                        <MenuButton>Lead</MenuButton>
                        <Menu>
                            {leads.map((lead, index) => (
                                <MenuItem key={index}>
                                    <Typography fontWeight="bold" sx={{ mr: 1 }}>
                                        {lead.type}:
                                    </Typography>
                                    <Typography>
                                        {lead.value}
                                    </Typography>
                                </MenuItem>
                            ))}
                            <ListDivider />
                            <MenuItem component="a" href={`/lead/${lead_id}`}  sx={{ justifyContent: 'center' }}>
                                Gestisci lead
                            </MenuItem>
                        </Menu>
                    </Dropdown>
                )}
                <Button
                    color="neutral"
                    variant="outlined"
                    size="sm"
                    sx={{
                        display: { xs: 'none', md: 'inline-flex' },
                    }}
                    onClick={handleGenerateLead}
                >
                    {displaySender.name !== 'Anonimo' ? 'Rigenera Lead' : 'Genera Lead'}
                </Button>
                <IconButton size="sm" variant="plain" color="neutral">
                    <MoreVertRoundedIcon />
                </IconButton>
            </Stack>
        </Stack>
    );
};

export default MessagesPaneHeader;
