import React, {useState, useEffect} from 'react';
import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import IconButton from '@mui/joy/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Tooltip} from '@mui/joy';
import ApiCaller from "../../components/ApiCaller";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Alert from '@mui/joy/Alert';
import Divider from '@mui/joy/Divider';
import Stack from '@mui/joy/Stack';

export default function ChatApi() {
    const [inputValue, setInputValue] = useState('');
    const [apiData, setApiData] = useState(null);

    const handleCopyClick = () => {
        if (inputValue) {
            navigator.clipboard.writeText(inputValue)
                .then(() => {
                    toast.success('Chiave API copiata', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                })
                .catch((err) => {
                    console.error('Failed to copy text: ', err);
                });
        }
    };

    useEffect(() => {
        if (apiData && apiData[0] && apiData[0].key) {
            setInputValue(apiData[0].key);
        }
    }, [apiData]);

    return (
        <Box sx={{display: {xs: 'block'}}}>
            <Stack
                spacing={1}
                sx={{
                    display: 'flex',
                    width: '100%',
                    maxWidth: '1600px',
                    mx: 'auto',
                    px: { xs: 2, md: 6 },
                    py: { xs: 2, md: 3 },
                }}
            >
            <FormControl>
                <FormLabel>La tua chiavi API</FormLabel>
                <ApiCaller pageName="/apikey" method="GET" queryParams="">
                    {({data, loading, error}) => {
                        if (data !== apiData) {
                            setApiData(data);
                        }
                        return (
                            <>
                                {loading && <p style={{textAlign: 'center'}}>Loading...</p>}
                                {error && <p>Error: {error.message}</p>}
                                {data && (
                                    <Input
                                        value={inputValue}
                                        readOnly
                                        onChange={(e) => setInputValue(e.target.value)}
                                        endDecorator={
                                            <Tooltip title="Copy to clipboard">
                                                <IconButton onClick={handleCopyClick}>
                                                    <ContentCopyIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        placeholder="Chiave api pubblica"
                                        sx={{
                                            '--Input-minHeight': '56px',
                                            '--Input-radius': '6px',
                                        }}
                                    />
                                )}
                            </>
                        );
                    }}
                </ApiCaller>
            </FormControl>
            <Alert variant="outlined" sx={{ mt: 2, mb: 2 }}>Per connetterti alle API utilizza la tua email e la tua password come chiave segreta.</Alert>

            <Divider />

            <ToastContainer/>
            </Stack>
        </Box>
    );
}
