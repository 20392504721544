import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import CssBaseline from '@mui/material/CssBaseline';
import ApiCaller from '../../components/ApiCaller';
import DynamicForm from "../../components/DynamicForm";

export default function ChatSetting() {
    const [refresh, setRefresh] = useState(false);
    const [page, setPage] = useState(0);

    const handleSubmit = async (formData, method, url, sendData) => {
        console.log('Submitting form data:', formData);
        try {
            await sendData(method, url, formData); // Passa l'ID dell'istruzione
            setRefresh(!refresh); // Trigger refresh after submit
        } catch (error) {
            console.error('Errore durante l\'invio del modulo:', error);
        }
    };

    const fields = [
        { name: 'name', type: 'input', label: 'Nome', required: true },
        { name: 'position', type: 'input', label: 'Posizione', required: true },
        { name: 'avatar', type: 'input', label: 'Avatar', required: true },
        { name: 'message', type: 'textarea', label: 'Messaggio di benvenuto', required: true },
    ];

    return (
        <Box sx={{ display: { xs: 'block' } }}>
            <CssBaseline />
            <ApiCaller pageName="/settings" method="GET" queryParams="" refresh={refresh} page={page}>
                {({ data, loading, error, sendData }) => (
                    <>
                        {loading && <p style={{ textAlign: 'center' }}>Loading...</p>}
                        {error && <p>Error: {error.message}</p>}
                        {data && data.length > 0 && (
                            <DynamicForm
                                fields={fields}
                                onSubmit={(formData, method, url) => handleSubmit(formData, method, url, sendData)}
                                baseUrl="/settings"
                                title='Settings'
                                description='Modifica le opzioni iniziali della chat'
                                initialValues={data[0]}
                                autoSave={false}
                            />
                        )}
                    </>
                )}
            </ApiCaller>
        </Box>
    )
}
