import React, { useState, useEffect, useRef } from 'react';
import Checkbox from '@mui/joy/Checkbox';
import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Switch from '@mui/joy/Switch';
import Textarea from '@mui/joy/Textarea';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/joy/FormLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import Stack from '@mui/joy/Stack';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
    };
};

const DynamicForm = ({ fields, onSubmit, title, description, initialValues, onDelete, autoSave = false, deleting = false, baseUrl, nameInput, nameLabel }) => {
    const [formValues, setFormValues] = useState(initialValues || {});
    const [localFields, setLocalFields] = useState(fields);

    const debounceSaveRef = useRef(debounce(async (name, value) => {
        const id = name !== 'content' ? name : null;
        const updatedValues = {
            ...formValues,
            [id || 'content']: value,
        };

        const url = id ? `${baseUrl}/${id}` : baseUrl;
        const method = id ? 'PUT' : 'POST';

        try {
            const savedField = await onSubmit({ content: value }, method, url);
            if (method === 'POST' && savedField && savedField.id) {
                setLocalFields(prevFields => prevFields.map(f => (f.name === name ? { ...f, id: savedField.id } : f)));
                setFormValues(prevValues => ({
                    ...prevValues,
                    [savedField.id]: value,
                }));
            } else if (method === 'PUT') {
                setFormValues(prevValues => ({
                    ...prevValues,
                    [name]: value,
                }));
            }
            if (name === 'content') {
                handleAddNewField();
            }
        } catch (error) {
            console.error('Error saving field:', error);
        }
    }, 1000));

    useEffect(() => {
        if (initialValues) {
            setFormValues(initialValues);
        }
    }, [initialValues]);

    useEffect(() => {
        if (localFields.length === 0) {
            handleAddNewField();
        }
    }, [localFields]);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: newValue,
        }));

        if (autoSave) {
            debounceSaveRef.current(name, newValue);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const url = `${baseUrl}/${formValues.id || ''}`;
        onSubmit(formValues, 'PUT', url);
    };

    const handleDelete = (field) => {
        onDelete(field);
        setLocalFields((prevFields) => prevFields.filter(f => f.name !== field));
    };

    const handleAddNewField = () => {
        const newField = {
            name: `${nameInput}-${Date.now()}`,
            label: `${nameLabel}`,
            type: 'input',
            required: false,
            value: '',
        };
        setLocalFields((prevFields) => [...prevFields, newField]);
        setFormValues((prevValues) => ({
            ...prevValues,
            [newField.name]: '',
        }));
    };

    useEffect(() => {
        setLocalFields(fields);
    }, [fields]);

    return (
        <Stack
            spacing={1}
            sx={{
                display: 'flex',
                width: '100%',
                maxWidth: '1600px',
                mx: 'auto',
                px: { xs: 2, md: 6 },
                py: { xs: 2, md: 3 },
            }}
        >
            <Card>
                <Box sx={{ mb: 1 }}>
                    <Typography level="title-md">{title}</Typography>
                    <Typography level="body-sm">{description}</Typography>
                </Box>
                <Divider />
                <form onSubmit={handleSubmit}>
                    {localFields.map((field, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                            {field.type === 'checkbox' ? (
                                <FormControlLabel
                                    control={<Checkbox name={field.name} color="primary" checked={formValues[field.name] || false} onChange={handleChange} />}
                                    label={field.label}
                                />
                            ) : field.type === 'select' ? (
                                <FormControl fullWidth>
                                    <FormLabel id={`${field.name}-label`}>{field.label}</FormLabel>
                                    <Select
                                        labelId={`${field.name}-label`}
                                        name={field.name}
                                        value={formValues[field.name] || ''}
                                        onChange={handleChange}
                                        required={field.required}
                                    >
                                        {field.options.map((option, index) => (
                                            <Option key={index} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : field.type === 'switch' ? (
                                <FormControlLabel
                                    control={<Switch name={field.name} color="primary" checked={formValues[field.name] || false} onChange={handleChange} />}
                                    label={field.label}
                                />
                            ) : field.type === 'textarea' ? (
                                <Stack spacing={1} mb={2} sx={{ flexGrow: 1 }}>
                                    <FormLabel htmlFor={field.name}>{field.label}</FormLabel>
                                    <FormControl sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}>
                                        <Textarea
                                            name={field.name}
                                            minRows={6}
                                            id={field.name}
                                            value={formValues[field.name] || ''}
                                            onChange={handleChange}
                                            placeholder={field.label}
                                            required={field.required}
                                        />
                                    </FormControl>
                                </Stack>
                            ) : (
                                <Stack spacing={1} mb={2} sx={{ flexGrow: 1 }}>
                                    <FormLabel htmlFor={field.name}>{field.label}</FormLabel>
                                    <FormControl sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}>
                                        <Input
                                            id={field.name}
                                            name={field.name}
                                            type={field.type}
                                            value={formValues[field.name] || ''}
                                            onChange={handleChange}
                                            required={field.required}
                                        />
                                    </FormControl>
                                </Stack>
                            )}
                            {deleting && (
                                <IconButton color="error" onClick={() => handleDelete(field.name)}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </div>
                    ))}
                    {!autoSave && (
                        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                                <Button size="sm" variant="solid" type="submit">
                                    Salva
                                </Button>
                            </CardActions>
                        </CardOverflow>
                    )}
                </form>
            </Card>
        </Stack>
    );
};

export default DynamicForm;
