import * as React from 'react';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { format, isValid } from 'date-fns';
import { it } from 'date-fns/locale';
import IconButton from '@mui/joy/IconButton';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';


const ChatBubble = ({ content, variant, timestamp, sender }) => {
    const isSent = variant === 'sent';
    const [isHovered, setIsHovered] = React.useState(false);
    const [isLiked, setIsLiked] = React.useState(false);
    const [isCelebrated, setIsCelebrated] = React.useState(false);

    // Controlla se il timestamp è valido
    const formattedTimestamp = isValid(new Date(timestamp))
        ? format(new Date(timestamp), 'dd MMMM yyyy, HH:mm', { locale: it })
        : format(new Date(), 'dd MMMM yyyy, HH:mm', { locale: it });

    return (
        <Box sx={{ maxWidth: '60%', minWidth: 'auto' }}>
            <Stack
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{ mb: 0.25 }}
            >
                <Typography level="body-xs">
                    {sender.name}
                </Typography>
                <Typography level="body-xs">{formattedTimestamp}</Typography>
            </Stack>
            <Box
                sx={{ position: 'relative' }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <Box
                    sx={{
                        p: 1.25,
                        borderRadius: 'lg',
                        borderTopRightRadius: isSent ? 0 : 'lg',
                        borderTopLeftRadius: isSent ? 'lg' : 0,
                        backgroundColor: isSent
                            ? 'var(--joy-palette-primary-solidBg)'
                            : 'background.body',
                    }}
                >
                    <Typography
                        level="body-sm"
                        sx={{
                            color: isSent
                                ? 'var(--joy-palette-common-white)'
                                : 'var(--joy-palette-text-primary)',
                        }}
                    >
                        {content}
                    </Typography>
                </Box>
                {(isHovered || isLiked || isCelebrated) && (
                    <Stack
                        direction="row"
                        justifyContent={isSent ? 'flex-end' : 'flex-start'}
                        spacing={0.5}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            p: 1.5,
                            ...(isSent
                                ? {
                                    left: 0,
                                    transform: 'translate(-100%, -50%)',
                                }
                                : {
                                    right: 0,
                                    transform: 'translate(100%, -50%)',
                                }),
                        }}
                    >
                        <IconButton
                            variant={isLiked ? 'soft' : 'plain'}
                            color={isLiked ? 'danger' : 'neutral'}
                            size="sm"
                            onClick={() => setIsLiked((prevState) => !prevState)}
                        >
                            {isLiked ? '❤️' : <FavoriteBorderIcon />}
                        </IconButton>
                        <IconButton
                            variant={isCelebrated ? 'soft' : 'plain'}
                            color={isCelebrated ? 'warning' : 'neutral'}
                            size="sm"
                            onClick={() => setIsCelebrated((prevState) => !prevState)}
                        >
                            {isCelebrated ? '🎉' : <CelebrationOutlinedIcon />}
                        </IconButton>
                    </Stack>
                )}
            </Box>
        </Box>
    );
};

export default ChatBubble;
