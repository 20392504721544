import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({
        token: Cookies.get('access_token'),
        refreshToken: Cookies.get('refresh_token'),
    });

    useEffect(() => {
        if (auth.token) {
            Cookies.set('access_token', auth.token, { expires: 1 });
        } else {
            Cookies.remove('access_token');
        }
        if (auth.refreshToken) {
            Cookies.set('refresh_token', auth.refreshToken, { expires: 1 });
        } else {
            Cookies.remove('refresh_token');
        }
    }, [auth.token, auth.refreshToken]);

    const login = (token, refreshToken) => {
        setAuth({ token, refreshToken });
    };

    const logout = () => {
        setAuth({ token: null, refreshToken: null });
        Cookies.remove('access_token');
        Cookies.remove('refresh_token');
    };

    return (
        <AuthContext.Provider value={{ auth, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
