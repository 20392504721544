import * as React from 'react';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import Stack from '@mui/joy/Stack';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import AvatarWithStatus from './AvatarWithStatus';
import { format, differenceInMinutes, parseISO, isValid } from 'date-fns';
import { it } from 'date-fns/locale';
import { API_ENDPOINT } from '../constants/constants';
import Cookies from 'js-cookie';

const formatTimestamp = (isoString) => {
    const date = parseISO(isoString);
    if (!isValid(date)) {
        console.error('Invalid date:', isoString);
        return format(new Date(), 'dd MMMM yyyy, HH:mm', { locale: it });
    }
    return format(date, 'd MMMM yyyy, HH:mm', { locale: it });
};

const ChatListItem = ({ chat, selected, setSelectedChat, updateChatStatus }) => {
    const now = new Date();
    const [isOnline, setIsOnline] = React.useState(false);
    const [hasUnreadMessages, setHasUnreadMessages] = React.useState(chat.messages.some(message => message.unread === 1));

    React.useEffect(() => {
        if (chat && chat.messages) {
            const lastMessage = chat.messages.length > 0
                ? chat.messages.slice().reverse().find(message => message.message)
                : null;

            if (lastMessage && lastMessage.timestamp) {
                const lastMessageDate = parseISO(lastMessage.timestamp);
                if (isValid(lastMessageDate)) {
                    const updateOnlineStatus = () => {
                        const now = new Date();
                        setIsOnline(differenceInMinutes(now, lastMessageDate) <= 3);
                    };

                    updateOnlineStatus();
                    const intervalId = setInterval(updateOnlineStatus, 30000); // Ogni 30 secondi

                    return () => clearInterval(intervalId);
                } else {
                    console.error('Invalid lastMessageDate:', lastMessage.timestamp);
                }
            } else {
                console.error('lastMessage or lastMessage.timestamp is undefined:', lastMessage);
            }
        } else {
            console.error('Chat or chat.messages is undefined:', chat);
        }
    }, [chat]);

    if (!chat || !chat.messages) {
        return null; // O un rendering alternativo
    }

    const lastMessage = chat.messages.length > 0
        ? chat.messages.slice().reverse().find(message => message.message)
        : null;

    if (!lastMessage || !lastMessage.message) {
        return null; // O un rendering alternativo
    }

    const truncatedMessage = lastMessage.message.length > 50
        ? `${lastMessage.message.substring(0, 50)}...`
        : lastMessage.message;

    const formattedTimestamp = formatTimestamp(lastMessage.timestamp);

    const handleChatClick = async () => {
        setSelectedChat(chat);

        // Invia richiesta per aggiornare i messaggi come letti
        const token = Cookies.get('access_token');
        try {
            const response = await fetch(`${API_ENDPOINT}/chat/markAsRead`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ session_id: chat.session }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Aggiorna lo stato della chat per riflettere i messaggi letti
            updateChatStatus(chat.session);
            setHasUnreadMessages(false);
            console.log('Messages marked as read for session:', chat.session);
        } catch (error) {
            console.error('Error marking messages as read:', error);
        }
    };

    return (
        <ListItem>
            <ListItemButton
                onClick={handleChatClick}
                selected={selected}
                color="neutral"
                sx={{
                    flexDirection: 'column',
                    alignItems: 'initial',
                    gap: 1,
                }}
            >
                <Stack direction="row" spacing={1.5}>
                    <AvatarWithStatus online={isOnline} src="/static/images/avatar/1.jpg" />
                    <Box sx={{ flex: 1 }}>
                        <Typography level="title-sm">{chat.sender.name}</Typography>
                        <Typography level="body-sm">{truncatedMessage}</Typography>
                    </Box>
                    <Box
                        sx={{
                            lineHeight: 1.5,
                            textAlign: 'right',
                        }}
                    >
                        {hasUnreadMessages && <CircleIcon sx={{ fontSize: 12 }} color="primary" />}
                        <Typography
                            level="body-xs"
                            display={{ xs: 'none', md: 'block' }}
                            noWrap
                        >
                            {formattedTimestamp}
                        </Typography>
                    </Box>
                </Stack>
            </ListItemButton>
        </ListItem>
    );
};

export default ChatListItem;
